// Common types

export type SerializableObject = {
  [key: string]: Serializable
  [key: number]: Serializable
}

export type SerializableArray = Array<Serializable>

export type Serializable =
  | null
  | boolean
  | SerializableObject
  | SerializableArray
  | number
  | string

// -------- JSON Schema Types --------
export enum JSONSchemaType {
  null = 'null',
  boolean = 'boolean',
  object = 'object',
  array = 'array',
  number = 'number',
  integer = 'integer',
  string = 'string'
}
export interface JSONSchemaChoice {
  value: any
  label?: string
  groupLabel?: string
}
export interface JSONSchemaRef {
  $ref: string
}
export interface JSONSchema {
  definitions?: {
    [key: string]: JSONSchema
  }
  title?: string
  type?: JSONSchemaType
  description?: string
  defaultValue?: any
  properties?: {
    [key: string]: JSONSchema | JSONSchemaRef
  }
  required?: string[]
  items?: JSONSchema | JSONSchemaRef
  format?: string
  choices?: JSONSchemaChoice[]
}
// ------ End JSON Schema types ------

// App specific types

export interface User {
  id: number
  email: string
  date_joined: string
  has_access_to_google: boolean
  vtiger_username: string | null
  vtiger_access_key: string | null
  vtiger_base_url: string | null
}

export enum StatisticsPeriod {
  Week = 'WEEK' as any,
  Month = 'MONTH' as any,
  Quarter = 'QUARTER' as any
}

export enum ReportModelStatus {
  Idle = 'IDLE' as any,
  Queue = 'QUEUE' as any,
  Preparing = 'PREPARING' as any,
  CollectingStatistics = 'COLLECTING_STATISTICS' as any,
  GeneratingTable = 'GENERATING_TABLE' as any,
  Exporting = 'EXPORTING' as any
}

export type ReportMessageType = 'info' | 'success' | 'warning' | 'error'

export interface ReportMessage{
  title: string
  text: string
  type: ReportMessageType
}

export interface LastLaunchReport {
  unhandled_error?: string
  messages?: ReportMessage[]
}

export interface ReportModel {
  id: number
  name: string
  is_active: boolean
  report_objects: number[]
  metrics: Record<string, any>[]
  export_methods: Record<string, any>[]
  statistics_period: StatisticsPeriod
  status: ReportModelStatus
  include_trend: boolean
  last_launch_time: string
  last_launch_report: LastLaunchReport
}

export interface ReportObject {
  id: number
  name: string
  bindings: {
    [key: string]: {
      [key: string]: Serializable
    }
  }
}
