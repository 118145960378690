import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TextField } from '@material-ui/core'
import { Autocomplete, AutocompleteProps } from '@material-ui/lab'
import _ from 'lodash'


interface SelectProps {
  name: string
  defaultValue?: any
  helperText?: string
  label?: string
  className?: string
  disableWhileSubmitting?: boolean
  getOptionValue?: (option: any) => any
}

const Select: React.FC<
  Omit<AutocompleteProps<any, undefined, undefined, undefined>, 'renderInput'> &
    SelectProps
> = ({
  name,
  label,
  defaultValue,
  helperText,
  className,
  disableWhileSubmitting = true,
  getOptionValue = (option) => option,
  ...other
}) => {
  const { control, formState } = useFormContext()
  const error: string | undefined = _.get(formState.errors, name)?.message

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ?? null}
      render={({ field: { onChange, value, ...field } }) => {
        const autocompleteValue =
          other.options.find((v) => _.isEqual(getOptionValue(v), value)) ??
          value ??
          null

        return (
          <Autocomplete
            className={className}
            value={autocompleteValue}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                helperText={error || helperText}
                error={Boolean(error)}
                variant='outlined'
              />
            )}
            disabled={disableWhileSubmitting && formState.isSubmitting}
            onChange={(event, value) =>
              onChange(value === null ? null : getOptionValue(value))
            }
            {...other}
            {...field}
          />
        )
      }}
    />
  )
}

export default Select
