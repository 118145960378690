import { createSlice } from '@reduxjs/toolkit'
import { api } from 'app/services/api'
import type { RootState } from '../../app/store'
import { User } from 'types'


type AuthState = {
  user: User | null
  token: string | null
  isAuthenticated: boolean | null
}

const slice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    token: localStorage.getItem('token'),
    isAuthenticated: localStorage.getItem('token') ? null : false
  } as AuthState,
  reducers: {
    forceLogout: (state) => {
      state.user = null
      state.token = null
      state.isAuthenticated = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(api.endpoints.login.matchFulfilled, (state, { payload }) => {
        state.token = payload.token
        localStorage.setItem('token', payload.token)
        state.user = payload.user
        state.isAuthenticated = true
      })
      .addMatcher(api.endpoints.updateUser.matchFulfilled, (state, { payload }) => {
        state.user = payload
      })
      .addMatcher(api.endpoints.logout.matchFulfilled, (state) => {
        state.token = null
        state.user = null
        state.isAuthenticated = false
      })
      .addMatcher(
        api.endpoints.getUser.matchFulfilled,
        (state, { payload }) => {
          state.user = payload
          state.isAuthenticated = true
        }
      )
      .addMatcher(api.endpoints.getUser.matchRejected, (state) => {
        state.isAuthenticated = false
      })
  }
})

export const { forceLogout } = slice.actions

export default slice.reducer

export const selectAuth = (state: RootState): RootState['auth'] => state.auth
export const selectUser = (state: RootState): RootState['auth']['user'] => state.auth.user
export const selectIsAuthenticated = (state: RootState): RootState['auth']['isAuthenticated'] =>
  state.auth.isAuthenticated
