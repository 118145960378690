import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import { ArrowDownward, ArrowUpward, Delete, ExpandMore } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import _ from 'lodash'
import { useFieldArray, useFormContext } from 'react-hook-form'

import { SchemaUserErrorsList } from 'components'
import { Input } from 'components/fields'
import { ExportMethodsSchema, useGetExportMethodsSchemaQuery } from 'app/services/api'
import JSONSchemaField, { getDefaultValuesBySchema } from './JSONSchema'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column'
    },
    accordionSummary: {
      '& > div': {
        alignItems: 'center'
      }
    },
    listDivider: {
      margin: theme.spacing(2, 0, 2)
    },
    textDisabled: {
      color: theme.palette.text.disabled
    },
    exportMethodItemIndex: {
      color: theme.palette.text.disabled,
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginRight: theme.spacing(2)
    }
  })
)

interface ExportMethodsFieldProps {
  name: string
  className?: string
  disabled?: boolean
}

interface ExportMethodItemProps {
  name: string
  index?: number
  disabled?: boolean
  value: {
    [key: string]: any
  }
  movable: boolean
  onMoveUp: () => void
  onMoveDown: () => void
  onRemove: () => void
}

const ExportMethodItem: React.FC<ExportMethodItemProps> = ({
  name,
  value,
  disabled,
  index,
  movable,
  onMoveUp,
  onMoveDown,
  onRemove
}) => {
  const classes = useStyles()
  const { data: exportMethodsSchema } = useGetExportMethodsSchemaQuery()

  return (
    <Accordion>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMore />}
      >
        <Typography className={classes.exportMethodItemIndex}>{`#${
          index !== undefined ? index + 1 : '?'
        }`}</Typography>
        <Input
          onClick={(e) => e.stopPropagation()}
          onFocus={(e) => e.stopPropagation()}
          defaultValue={value.alias || ''}
          disabled={disabled}
          variant='outlined'
          label='Alias'
          name={`${name}.alias`}
        />
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            onMoveUp()
          }}
          onFocus={(e) => e.stopPropagation()}
          aria-label='move up'
          disabled={!movable || disabled}
        >
          <ArrowUpward />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            onMoveDown()
          }}
          onFocus={(e) => e.stopPropagation()}
          aria-label='move down'
          disabled={!movable || disabled}
        >
          <ArrowDownward />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            onRemove()
          }}
          onFocus={(e) => e.stopPropagation()}
          aria-label='delete'
          disabled={disabled}
        >
          <Delete />
        </IconButton>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <JSONSchemaField
          schema={exportMethodsSchema?.schema[value.name] || {}}
          name={`${name}.properties`}
          disabled={disabled}
        />
      </AccordionDetails>
    </Accordion>
  )
}

const getExportMethodOptions = (
  exportMethodsSchema: ExportMethodsSchema | undefined
) => {
  if (!exportMethodsSchema?.schema) return []

  const options = []
  for (const [name, schema] of Object.entries(exportMethodsSchema.schema)) {
    const label = schema.title || name
    options.push({
      label: label,
      exportMethodGenerator: () => ({
        alias: label,
        name: name,
        properties: getDefaultValuesBySchema(schema)
      })
    })
  }

  return options
}

const ExportMethodsField: React.FC<ExportMethodsFieldProps> = ({
  name,
  className,
  disabled = false
}) => {
  const classes = useStyles()
  const {
    data: exportMethodsSchema,
    error,
    isFetching
  } = useGetExportMethodsSchemaQuery()
  const exportMethodOptions = React.useMemo(
    () => getExportMethodOptions(exportMethodsSchema),
    [exportMethodsSchema]
  )
  const { control } = useFormContext()
  const { fields, append, remove, swap } = useFieldArray({
    control,
    name
  })
  const [selectInputValue, setSelectInputValue] = React.useState('')

  if (isFetching) {
    return <CircularProgress />
  } else if (error) {
    return <Typography>{'Failed to load export methods schema.'}</Typography>
  }

  const lastFieldIndex = fields.length - 1

  return (
    <Accordion className={className}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>Export methods</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {fields.length > 0 ? (
          fields.map(({ id, ...data }, index) => (
            <ExportMethodItem
              name={`${name}.${index}`}
              key={id}
              value={data as any}
              index={index}
              disabled={disabled}
              movable={fields.length > 1}
              onRemove={() => remove(index)}
              onMoveUp={() =>
                index === 0 ? swap(0, lastFieldIndex) : swap(index, index - 1)
              }
              onMoveDown={() =>
                index === lastFieldIndex
                  ? swap(lastFieldIndex, 0)
                  : swap(index, index + 1)
              }
            />
          ))
        ) : (
          <Typography align='center' className={classes.textDisabled}>
            No export methods specified
          </Typography>
        )}

        {exportMethodsSchema?.errors && !_.isEmpty(exportMethodsSchema.errors) && (
          <>
            <Divider className={classes.listDivider} />
            <SchemaUserErrorsList errors={exportMethodsSchema.errors} />
          </>
        )}

        <Divider className={classes.listDivider} />
        <Autocomplete
          options={exportMethodOptions}
          onChange={(event, value, reason) => {
            if (reason === 'select-option' && value?.exportMethodGenerator) {
              append(value.exportMethodGenerator())
              setSelectInputValue('')
            }
          }}
          getOptionLabel={(option) => option.label}
          value={null}
          disabled={disabled}
          blurOnSelect={true}
          clearOnEscape={true}
          inputValue={selectInputValue}
          onInputChange={(event, newInputValue) => {
            setSelectInputValue(newInputValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Select export method'
              helperText='Select export method to add'
              variant='outlined'
            />
          )}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default ExportMethodsField
