import React from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  createStyles,
  Divider,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography
} from '@material-ui/core'
import { ArrowDownward, ArrowUpward, Delete, ExpandMore } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { useFieldArray, useFormContext } from 'react-hook-form'
import deepcopy from 'deepcopy'

import JSONSchemaField, { getDefaultValuesBySchema } from './JSONSchema'
import Input from './Input'
import { SchemaUserErrorsList } from 'components'
import { StatisticsServicesSchema, useGetStatisticsServicesSchemaQuery } from 'app/services/api'
import _ from 'lodash'
import Checkbox from './Checkbox'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column',
      '& > *:not(:last-child)': {
        marginBottom: theme.spacing(2)
      }
    },
    accordionSummary: {
      '& > div': {
        alignItems: 'center'
      }
    },
    textDisabled: {
      color: theme.palette.text.disabled
    },
    metricItemIndex: {
      color: theme.palette.text.disabled,
      fontSize: '1.5rem',
      fontWeight: 'bold',
      marginRight: theme.spacing(2)
    }
  })
)

function getMetricOptions(
  statisticsServicesSchema: StatisticsServicesSchema['schema'] | undefined = {}
): {
  name: string
  title: string
  service: string
  serviceTitle: string
  defaultValue: { [key: string]: any }
}[] {
  const options = []

  for (const [serviceName, service] of Object.entries(
    statisticsServicesSchema
  )) {
    const serviceTitle = service.title || serviceName

    for (const [metricName, metricSchema] of Object.entries(
      service.metrics || {}
    )) {
      const metricTitle = metricSchema.title || metricName
      options.push({
        name: metricName,
        title: metricTitle,
        service: serviceName,
        serviceTitle: serviceTitle,
        defaultValue: {
          is_good: true,
          properties: getDefaultValuesBySchema(metricSchema) as {
            [key: string]: any
          }
        }
      })
    }
  }

  options.push({
    name: 'group',
    title: 'Metric Group',
    service: '$other',
    serviceTitle: 'Other',
    defaultValue: { metrics: [] }
  })

  return options
}

interface MetricFieldProps {
  value: {
    id: string
    alias: string
    name: string
    service: string
    properties: {
      [key: string]: any
    }
  }
  name: string
  control: any
  index: number
  rootIndices?: number[]
  schema: StatisticsServicesSchema['schema']
  disabled?: boolean
  movable: boolean
  onRemove: (index: number) => void
  onMoveUp: (index: number) => void
  onMoveDown: (index: number) => void
}

const MetricField: React.FC<MetricFieldProps> = ({
  value: metric,
  name,
  control,
  index,
  rootIndices = [],
  schema,
  disabled,
  movable,
  onRemove,
  onMoveUp,
  onMoveDown
}) => {
  const classes = useStyles()

  return (
    <Accordion>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMore />}
      >
        <Typography className={classes.metricItemIndex}>
          {`#${[...rootIndices, index + 1].join('.')}`}
        </Typography>
        <Input
          onClick={(e) => e.stopPropagation()}
          onFocus={(e) => e.stopPropagation()}
          defaultValue={metric.alias || ''}
          disabled={disabled}
          variant='outlined'
          label='Alias'
          name={`${name}.alias`}
        />
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            onMoveUp(index)
          }}
          onFocus={(e) => e.stopPropagation()}
          aria-label='move up'
          disabled={!movable || disabled}
        >
          <ArrowUpward />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            onMoveDown(index)
          }}
          onFocus={(e) => e.stopPropagation()}
          aria-label='move down'
          disabled={!movable || disabled}
        >
          <ArrowDownward />
        </IconButton>
        <IconButton
          onClick={(e) => {
            e.stopPropagation()
            onRemove(index)
          }}
          onFocus={(e) => e.stopPropagation()}
          aria-label='delete'
          disabled={disabled}
        >
          <Delete />
        </IconButton>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {metric.service === '$other' && metric.name === 'group' ? (
          <MetricsFieldBody name={`${name}.metrics`} control={control} />
        ) : (
          <>
            <Checkbox
              helperText='Determines if it is good that the trend of the metric is growing'
              name={`${name}.is_good`}
              label='Is good'
            />
            <JSONSchemaField
              schema={
                _.get(
                  schema,
                  `${metric.service}.metrics.${metric.name}` || {}
                ) ?? {}
              }
              definitionGetter={(ref) =>
                _.get(schema, `${metric.service}.definitions.${ref}`) ?? {}
              }
              name={`${name}.properties`}
              disabled={disabled}
            />
          </>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

interface MetricsFieldBodyProps {
  name: string
  control: any
  rootIndices?: number[]
  disableWhileSubmitting?: boolean
}

const MetricsFieldBody: React.FC<MetricsFieldBodyProps> = ({
  name,
  control,
  rootIndices = [],
  disableWhileSubmitting = true
}) => {
  const classes = useStyles()
  const {
    data: servicesSchema,
    error,
    isLoading,
    isFetching
  } = useGetStatisticsServicesSchemaQuery()
  const metricsOptions = React.useMemo(
    () => getMetricOptions(servicesSchema?.schema),
    [servicesSchema]
  )
  const {
    formState: { isSubmitting }
  } = useFormContext()
  const { fields, append, remove, swap } = useFieldArray({ name, control })
  const [selectInputValue, setSelectInputValue] = React.useState('')

  const isDisabled = isFetching || (disableWhileSubmitting && isSubmitting)

  if (isLoading) {
    return <CircularProgress />
  } else if (error) {
    return <Typography>Failed to load metrics schema.</Typography>
  }

  const lastFieldIndex = fields.length - 1

  const onMoveUp = (index: number) => {
    if (fields.length > 1) {
      if (index === 0) {
        swap(0, lastFieldIndex)
      } else {
        swap(index, index - 1)
      }
    }
  }

  const onMoveDown = (index: number) => {
    if (fields.length > 1) {
      if (index === lastFieldIndex) {
        swap(lastFieldIndex, 0)
      } else {
        swap(index, index + 1)
      }
    }
  }

  return (
    <>
      {fields.length > 0 ? (
        fields.map((field, index) => (
          <MetricField
            key={field.id}
            name={`${name}.${index}`}
            control={control}
            disabled={isDisabled}
            value={field as any}
            rootIndices={rootIndices.map((i) => i + 1)}
            index={index}
            schema={servicesSchema?.schema || {}}
            movable={fields.length > 1}
            onRemove={remove}
            onMoveUp={onMoveUp}
            onMoveDown={onMoveDown}
          />
        ))
      ) : (
        <Typography align='center' className={classes.textDisabled}>
          There are no metrics yet
        </Typography>
      )}

      {servicesSchema?.errors && !_.isEmpty(servicesSchema.errors) && (
        <>
          <Divider />
          <SchemaUserErrorsList errors={servicesSchema.errors} />
        </>
      )}

      <Divider />
      <Autocomplete
        options={metricsOptions}
        onChange={(event, value, reason) => {
          if (reason === 'select-option' && value?.defaultValue) {
            append(
              Object.assign(
                {
                  name: value.name,
                  service: value.service,
                  alias: value.title
                },
                deepcopy(value.defaultValue) || {}
              )
            )
            setSelectInputValue('')
          }
        }}
        getOptionLabel={(option) => option.title}
        groupBy={(option) => option.serviceTitle}
        value={null}
        disabled={isDisabled}
        blurOnSelect={true}
        clearOnEscape={true}
        inputValue={selectInputValue}
        onInputChange={(event, newInputValue) => {
          setSelectInputValue(newInputValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label='Select metric'
            helperText='Select metric to add'
            variant='outlined'
          />
        )}
      />
    </>
  )
}

const MetricsField: React.FC<MetricsFieldBodyProps> = (props) => {
  const classes = useStyles()

  return (
    <Accordion>
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ExpandMore />}
      >
        <Typography>Metrics</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <MetricsFieldBody {...props} />
      </AccordionDetails>
    </Accordion>
  )
}

export default MetricsField
