import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {
  Button,
  CircularProgress,
  Container,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core'

import { useLoginMutation } from '../../app/services/api'
import { selectIsAuthenticated } from '../../features/auth/authSlice'
import { useApiErrorsParser } from '../../hooks/api'
import { Input } from 'components/fields'


const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Incorrect email format'),
  password: yup.string().required('Password is required')
})

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  formAlert: {
    width: '100%',
    marginTop: theme.spacing(2)
  }
}))

interface IFormInput {
  email: string
  password: string
}

const LoginPage: React.FC = () => {
  const classes = useStyles()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const location = useLocation() as any
  const [login] = useLoginMutation()
  const formMethods = useForm({ resolver: yupResolver(schema) })
  const parseError = useApiErrorsParser(formMethods.setError)

  if (isAuthenticated) {
    return (
      <Redirect to={{ pathname: location.state?.referrer?.pathname || '/' }} />
    )
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    login(data)
      .unwrap()
      .catch((e) => {
        parseError(e)
        formMethods.setValue('password', '')
      })
  }

  return (
    <Container component='main' maxWidth='xs'>
      <div className={classes.paper}>
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <FormProvider {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(onSubmit)}
            className={classes.form}
            noValidate
          >
            <Input
              name='email'
              defaultValue=''
              variant='outlined'
              margin='normal'
              fullWidth
              required
              label='Email Address'
              autoComplete='email'
              autoFocus
            />

            <Input
              name='password'
              defaultValue=''
              variant='outlined'
              margin='normal'
              fullWidth
              required
              label='Password'
              type='password'
              autoComplete='current-password'
            />

            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={formMethods.formState.isSubmitting}
            >
              {formMethods.formState.isSubmitting ? (
                <CircularProgress size='2rem' />
              ) : (
                'Sign in'
              )}
            </Button>
          </form>
        </FormProvider>
      </div>
    </Container>
  )
}

export default LoginPage
