import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Container, makeStyles, Theme } from '@material-ui/core'

import Navbar from './components/Navbar'
import ProtectedRoute from './components/ProtectedRoute'
import { LoginPage, ProfilePage } from './pages/auth'
import { selectIsAuthenticated } from 'features/auth/authSlice'
import { useGetUserQuery } from 'app/services/api'
import { CreateUpdateObject, ObjectsList } from 'pages/objects'
import { CreateUpdateModel, ModelsList } from 'pages/models'
import {default as MetricsReferencePage} from 'pages/MetricsReferencePage'


const useStyles = makeStyles((theme: Theme) => ({
  appBarSpacer: theme.mixins.toolbar
}))

const App: React.FC = () => {
  const classes = useStyles()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  useGetUserQuery(undefined, { skip: isAuthenticated !== null })

  return (
    <BrowserRouter>
      <Navbar />
      <div className={classes.appBarSpacer} />
      <Container component='main'>
        <Switch>
          <Route path='/' exact>
            <Redirect to='/models' />
          </Route>
          <Route path='/login' exact>
            <LoginPage />
          </Route>
          <ProtectedRoute path='/profile' exact>
            <ProfilePage />
          </ProtectedRoute>
          <ProtectedRoute path='/objects' exact>
            <ObjectsList />
          </ProtectedRoute>
          <ProtectedRoute path='/objects/create' exact>
            <CreateUpdateObject />
          </ProtectedRoute>
          <ProtectedRoute path='/objects/:id' exact>
            <CreateUpdateObject />
          </ProtectedRoute>
          <ProtectedRoute path='/models' exact>
            <ModelsList />
          </ProtectedRoute>
          <ProtectedRoute path='/models/create' exact>
            <CreateUpdateModel />
          </ProtectedRoute>
          <ProtectedRoute path='/models/:id' exact>
            <CreateUpdateModel />
          </ProtectedRoute>
          <ProtectedRoute path='/metrics-reference' exact>
            <MetricsReferencePage />
          </ProtectedRoute>
        </Switch>
      </Container>
    </BrowserRouter>
  )
}

export default App
