import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  createStyles,
  Divider,
  makeStyles,
  Paper,
  Theme,
  Typography
} from '@material-ui/core'
import { Add, Delete } from '@material-ui/icons'
import { DataGrid, GridColDef, GridRowId, GridToolbarContainer } from '@material-ui/data-grid'

import { useDeleteObjectsMutation, useGetObjectsQuery } from 'app/services/api'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    titleDivider: {
      margin: theme.spacing(1, 0, 2)
    }
  })
)

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1
  }
]

const ObjectsGridToolbar: React.FC<{ selectedObjectIds: number[] }> = ({
  selectedObjectIds
}) => {
  const [deleteObjects, { isLoading }] = useDeleteObjectsMutation()
  const history = useHistory()

  return (
    <GridToolbarContainer>
      <Button
        color='primary'
        startIcon={<Add />}
        onClick={() => history.push('/objects/create')}
      >
        Create new
      </Button>
      <Button
        disabled={selectedObjectIds.length === 0 || isLoading}
        color='secondary'
        startIcon={<Delete />}
        onClick={() =>
          window.confirm(
            `Are you sure you want to delete ${
              selectedObjectIds.length
            } report object${selectedObjectIds.length === 1 ? '' : 's'}?`
          ) && deleteObjects(selectedObjectIds).unwrap().finally()
        }
      >
        Delete
      </Button>
    </GridToolbarContainer>
  )
}

const ObjectsList: React.FC = () => {
  const { data: objects, isFetching, isError } = useGetObjectsQuery()
  const classes = useStyles()
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])
  const history = useHistory()

  React.useEffect(() => {
    if (objects) {
      const objectIds = objects.map((obj) => obj.id)
      setSelectionModel(
        selectionModel.filter((objId) => objectIds.includes(objId as number))
      )
    }
  }, [objects])

  return (
    <Paper className={classes.root}>
      <Typography variant='h4'>Report Objects</Typography>
      <Divider className={classes.titleDivider} />
      <DataGrid
        error={isError ? 'Error occurred' : undefined}
        rows={objects || []}
        columns={columns}
        loading={isFetching}
        disableColumnMenu
        autoHeight
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel)
        }}
        selectionModel={selectionModel}
        onRowClick={({ row: { id } }) =>
          !isFetching && history.push(`/objects/${id}`)
        }
        components={{ Toolbar: ObjectsGridToolbar }}
        componentsProps={{
          toolbar: {
            selectedObjectIds: selectionModel
          }
        }}
      />
    </Paper>
  )
}

export default ObjectsList
