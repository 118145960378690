import React from 'react'
import {
  Accordion, AccordionDetails, AccordionSummary, CircularProgress,
  createStyles,
  Divider, List, ListItem, ListItemText,
  makeStyles,
  Paper,
  Theme,
  Typography
} from '@material-ui/core'
import {ExpandMore} from '@material-ui/icons'

import { useGetStatisticsServicesSchemaQuery } from '../app/services/api'
import { JSONSchema } from '../types'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    titleDivider: {
      margin: theme.spacing(1, 0, 2)
    }
  })
)

interface ServiceAccordionProps {
  schema: {
    title: string
    metrics: {
      [key: string]: JSONSchema
    }
    definitions: {
      [key: string]: JSONSchema
    }
  }
}

const ServiceAccordion: React.FC<ServiceAccordionProps> = ({ schema }) => {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
        >
          <Typography>{schema.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            {Object.keys(schema.metrics).map((metricId) => {
              const metricSchema = schema.metrics[metricId]
  
              return (
                <ListItem key={metricId}>
                  <ListItemText
                    primary={metricSchema?.title ?? metricId}
                    secondary={metricSchema?.description ?? ''}
                  />
                </ListItem>
              )
            })}
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const MetricsReferencePage: React.FC = () => {
  const classes = useStyles()
  const { data: servicesSchema, isError, isFetching } = useGetStatisticsServicesSchemaQuery()

  let content
  if (isFetching) {
    content = <CircularProgress />
  } else if (isError || !servicesSchema || !servicesSchema.schema) {
    content = <Typography variant='h4'>Failed to load metrics schema</Typography>
  } else {
    const services = servicesSchema.schema

    content = Object.keys(services).map((serviceName) => {
      const serviceSchema = services[serviceName] as any
      return <ServiceAccordion key={serviceName} schema={serviceSchema}/>
    })
  }

  return (
    <Paper className={classes.root}>
      <Typography variant='h4'>Metrics reference</Typography>
      <Divider className={classes.titleDivider} />
      {content}
    </Paper>
  )
}

export default MetricsReferencePage
