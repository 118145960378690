import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query/react'

import { forceLogout } from 'features/auth/authSlice'
import type { RootState } from '../store'
import { JSONSchema, ReportModel, ReportObject, User } from 'types'


function providesList<R extends { id: string | number }[], T extends string>(
  resultsWithIds: R | undefined,
  tagType: T
) {
  return resultsWithIds
    ? [
      { type: tagType, id: 'LIST' },
      ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))
    ]
    : [{ type: tagType, id: 'LIST' }]
}

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token

    if (token) {
      headers.set('Authorization', `Token ${token}`)
    }

    return headers
  }
})
const baseQueryWithAutoLogout: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions)
  if (result.error && result.error.status === 401) {
    localStorage.removeItem('token')
    api.dispatch(forceLogout())
  }
  return result
}

export interface LoginResponse {
  user: User
  token: string
}

export interface LoginRequest {
  email: string
  password: string
}

export interface BindingsSchema {
  schema: {
    [key: string]: JSONSchema
  }
  errors: {
    [key: string]: string
  }
}

export interface ExportMethodsSchema {
  schema: {
    [key: string]: JSONSchema
  }
  errors: {
    [key: string]: string
  }
}

export interface StatisticsServicesSchema {
  schema: {
    [key: string]: {
      title?: string
      metrics: {
        [key: string]: JSONSchema
      }
      definitions: {
        [key: string]: JSONSchema
      }
    }
  }
  errors: {
    [key: string]: string
  }
}

export const api = createApi({
  baseQuery: baseQueryWithAutoLogout,
  tagTypes: [
    'User',
    'ReportObject',
    'ReportModel',
    'BindingsSchema',
    'ExportMethodsSchema',
    'StatisticsServicesSchema'
  ],
  endpoints: (build) => ({
    // Auth
    getUser: build.query<User, void>({
      query: () => 'auth/me/',
      providesTags: ['User']
    }),
    updateUser: build.mutation<User, Partial<User>>({
      query: (user_data) => ({
        url: 'auth/me/',
        method: 'PATCH',
        body: user_data
      }),
      invalidatesTags: ['User']
    }),
    login: build.mutation<LoginResponse, LoginRequest>({
      query: (credentials) => ({
        url: 'auth/login/',
        method: 'POST',
        body: credentials
      }),
      invalidatesTags: ['User']
    }),
    logout: build.mutation<void, void>({
      query: () => ({
        url: 'auth/logout/',
        method: 'POST'
      }),
      invalidatesTags: ['User']
    }),

    // Report objects
    addObject: build.mutation<ReportObject, Partial<ReportObject>>({
      query: (body) => ({
        url: 'reports/objects/',
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'ReportObject', id: 'LIST' }]
    }),
    getObjects: build.query<ReportObject[], void>({
      query: () => 'reports/objects/',
      providesTags: (result) => providesList(result, 'ReportObject')
    }),
    getObject: build.query<ReportObject, number>({
      query: (id) => `reports/objects/${id}/`,
      providesTags: (result, error, id) => [{ type: 'ReportObject', id }]
    }),
    updateObject: build.mutation<
      ReportObject,
      Pick<ReportObject, 'id'> & Partial<ReportObject>
    >({
      query: ({ id, ...patch }) => ({
        url: `reports/objects/${id}/`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ReportObject', id }]
    }),
    deleteObject: build.mutation<void, number>({
      query: (id) => ({
        url: `reports/objects/${id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'ReportObject', id },
        { type: 'ReportModel', id: 'LIST' } // Invalidates report models, since they can contain objects
      ]
    }),
    deleteObjects: build.mutation<void, number[]>({
      query: (ids) => ({
        url: 'reports/objects/delete_many/',
        method: 'DELETE',
        body: ids
      }),
      invalidatesTags: (result, error, ids) => [
        ...providesList(
          ids.map((id) => ({ id })),
          'ReportObject'
        ),
        { type: 'ReportModel', id: 'LIST' } // Invalidates report models, since they can contain objects
      ]
    }),
    // Bindings schema
    getObjectBindingsSchema: build.query<BindingsSchema, void>({
      query: () => 'reports/objects/bindings_schema/',
      providesTags: ['BindingsSchema']
    }),

    // Report models
    addModel: build.mutation<ReportModel, Partial<ReportModel>>({
      query: (body) => ({
        url: 'reports/models/',
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: 'ReportModel', id: 'LIST' }]
    }),
    getModels: build.query<ReportModel[], void>({
      query: () => 'reports/models/',
      providesTags: (result) => providesList(result, 'ReportModel')
    }),
    getModel: build.query<ReportModel, number>({
      query: (id) => `reports/models/${id}/`,
      providesTags: (result, error, id) => [{ type: 'ReportModel', id }]
    }),
    updateModel: build.mutation<
      ReportModel,
      Pick<ReportModel, 'id'> & Partial<ReportModel>
    >({
      query: ({ id, ...patch }) => ({
        url: `reports/models/${id}/`,
        method: 'PATCH',
        body: patch
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'ReportModel', id }]
    }),
    deleteModel: build.mutation<void, number>({
      query: (id) => ({
        url: `reports/models/${id}/`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, id) => [{ type: 'ReportModel', id }]
    }),
    deleteModels: build.mutation<void, number[]>({
      query: (ids) => ({
        url: 'reports/models/delete_many/',
        method: 'DELETE',
        body: ids
      }),
      invalidatesTags: (result, error, ids) =>
        providesList(
          ids.map((id) => ({ id })),
          'ReportModel'
        )
    }),
    duplicateModel: build.mutation<ReportModel, number>({
      query: (id) => ({
        url: `reports/models/${id}/duplicate/`,
        method: 'POST'
      }),
      invalidatesTags: [{ type: 'ReportModel', id: 'LIST' }]
    }),
    makeReports: build.mutation<void, number[]>({
      query: (ids) => ({
        url: 'reports/models/make_reports/',
        method: 'POST',
        body: ids
      }),
      invalidatesTags: [{ type: 'ReportModel', id: 'LIST' }]
    }),
    // Export methods schema
    getExportMethodsSchema: build.query<ExportMethodsSchema, void>({
      query: () => 'reports/models/export_methods_schema/',
      providesTags: ['ExportMethodsSchema']
    }),
    // Statistics services schema
    getStatisticsServicesSchema: build.query<StatisticsServicesSchema, void>({
      query: () => 'reports/models/statistics_services_schema/',
      providesTags: ['StatisticsServicesSchema']
    })
  })
})

export const {
  useGetUserQuery,
  useUpdateUserMutation,
  useLoginMutation,
  useLogoutMutation,
  useAddObjectMutation,
  useGetObjectsQuery,
  useGetObjectQuery,
  useDeleteObjectMutation,
  useDeleteObjectsMutation,
  useUpdateObjectMutation,
  useGetObjectBindingsSchemaQuery,
  useAddModelMutation,
  useGetModelsQuery,
  useGetModelQuery,
  useDeleteModelMutation,
  useDeleteModelsMutation,
  useUpdateModelMutation,
  useDuplicateModelMutation,
  useMakeReportsMutation,
  useGetExportMethodsSchemaQuery,
  useGetStatisticsServicesSchemaQuery
} = api
