import { useSnackbar } from 'notistack'
import { UseFormSetError } from 'react-hook-form'


const capitalizeFirstLetter = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useApiErrorsParser = (
  setError: UseFormSetError<any> = () => {}
) => {
  const { enqueueSnackbar } = useSnackbar()

  return (error: { data?: any; status?: number }) => {
    if (!error) return null

    let errorParsed = false
    for (const key in error.data) {
      const error_data: string[] | string = error.data[key]
      if (Array.isArray(error_data) && error_data.length > 0) {
        error_data.map((msg) =>
          key === 'detail' || key === 'non_field_errors'
            // Display general errors
            ? enqueueSnackbar(capitalizeFirstLetter(msg), {
              variant: 'error'
            })
            // Set field errors
            : setError(key, {
              message: capitalizeFirstLetter(msg),
              type: 'server-side-validation'
            })
        )
        errorParsed = true
      } else if (key === 'detail' && typeof error_data === 'string') {
        enqueueSnackbar(capitalizeFirstLetter(error_data), {
          variant: 'error'
        })
        errorParsed = true
      }
    }

    if (!errorParsed)
      enqueueSnackbar('Something went wrong!', {variant: 'error'})
  }
}
