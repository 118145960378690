import React from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import {
  AppBar,
  Button,
  Container,
  createStyles,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography
} from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'

import { selectAuth } from '../features/auth/authSlice'
import { useLogoutMutation } from '../app/services/api'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    title: {
      marginRight: theme.spacing(4)
    }
  })
)

const UserNav: React.FC = () => {
  const { user, isAuthenticated } = useSelector(selectAuth)
  const [logout] = useLogoutMutation()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  if (isAuthenticated === null) return null

  if (isAuthenticated) {
    return (
      <React.Fragment>
        <Button
          color='inherit'
          startIcon={<AccountCircle />}
          onClick={handleOpen}
        >
          {user?.email}
        </Button>
        <Menu
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorEl={anchorEl}
        >
          <MenuItem onClick={handleClose} component={RouterLink} to='/profile'>
            Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose()
              if (window.confirm('Are you sure you want to logout?')) {
                logout()
              }
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </React.Fragment>
    )
  } else {
    return (
      <Button color='inherit' component={RouterLink} to='/login'>
        Sign in
      </Button>
    )
  }
}

const Navbar: React.FC = () => {
  const classes = useStyles()

  return (
    <AppBar position='fixed'>
      <Container>
        <Toolbar>
          <Typography variant='h5' color='inherit' className={classes.title}>
            ReportingApp
          </Typography>
          <Button color='inherit' component={RouterLink} to='/models'>
            Models
          </Button>
          <Button color='inherit' component={RouterLink} to='/objects'>
            Objects
          </Button>
          <Button color='inherit' component={RouterLink} to='/metrics-reference'>
            Metrics
          </Button>

          <div className={classes.grow} />

          <UserNav />
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Navbar
