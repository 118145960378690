import React from 'react'
import {
  createStyles,
  Divider,
  FormHelperText,
  makeStyles,
  Paper,
  Theme,
  Typography
} from '@material-ui/core'
import { createFilterOptions } from '@material-ui/lab'
import _ from 'lodash'
import classnames from 'classnames'

import { Checkbox, Input, Select } from '.'
import { JSONSchema, JSONSchemaChoice, JSONSchemaRef, JSONSchemaType } from 'types'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    individualWrapper: {
      padding: theme.spacing(2)
    },
    wrapper: {},
    mtop: {
      marginTop: theme.spacing(2)
    },
    objectTitleDivider: {
      marginBottom: theme.spacing(1)
    },
    objectDescription: {
      marginBottom: theme.spacing(2)
    }
  })
)

interface JSONSchemaFieldProps {
  name: string
  schema: JSONSchema
  path?: string
  disabled?: boolean
  className?: string
  definitionGetter?: (ref: string, rootSchema: JSONSchema) => JSONSchema
}

const defaultDefinitionGetter = (ref: string, rootSchema: JSONSchema) =>
  (rootSchema?.definitions && rootSchema.definitions[ref]) || {}

const JSONSchemaField: React.FC<JSONSchemaFieldProps> = ({
  name,
  schema,
  path = '',
  className,
  disabled,
  definitionGetter = defaultDefinitionGetter
}) => {
  const classes = useStyles()

  let selfSchema: JSONSchema | JSONSchemaRef | undefined = path
    ? _.get(schema, path)
    : schema
  if (selfSchema === undefined) return null
  if ('$ref' in selfSchema) {
    const definitionPath = selfSchema.$ref
    const match = definitionPath.match(/^#\/definitions\/([a-zA-Z\d]+)/)
    const ref = match && match[1] ? match[1] : ''
    selfSchema = definitionGetter(ref, schema)
  }
  const selfType = selfSchema.type

  let renderAsIndividual = false

  let content

  if (selfSchema.choices) {
    content = (
      <Select
        name={name}
        label={selfSchema.title || 'Select...'}
        helperText={selfSchema.description}
        disabled={disabled}
        options={selfSchema.choices}
        getOptionValue={(option) => option.value ?? option}
        getOptionLabel={(option: JSONSchemaChoice | any) => {
          if (typeof option !== 'object' || option.value === undefined) {
            return JSON.stringify(option)
          }
          // Regular option
          return option.label ?? JSON.stringify(option.value)
        }}
        filterOptions={createFilterOptions({
          stringify: (option) => {
            let searchString = ''
            if (option.label) {
              searchString += option.label
            }
            if (option.groupLabel) {
              searchString += option.groupLabel
            }
            if (option.value !== null && option.value !== undefined) {
              searchString += option.value
            } else {
              searchString += JSON.stringify(option)
            }
            return searchString
          }
        })}
        groupBy={(option: JSONSchemaChoice) => option.groupLabel || ''}
      />
    )
  } else {
    let inputType
    const properties = selfSchema.properties || {}
    switch (selfType) {
      case JSONSchemaType.string:
        switch (selfSchema.format) {
          case 'email':
            inputType = 'email'
            break
          default:
            inputType = 'text'
        }
        content = (
          <Input
            name={name}
            type={inputType}
            defaultValue={selfSchema.defaultValue || ''}
            label={selfSchema.title}
            helperText={selfSchema.description}
            disabled={disabled}
            variant='outlined'
          />
        )
        break
      case JSONSchemaType.number:
      case JSONSchemaType.integer:
        content = (
          <Input
            name={name}
            type='number'
            defaultValue={selfSchema.defaultValue || ''}
            label={selfSchema.title}
            helperText={selfSchema.description}
            disabled={disabled}
            variant='outlined'
          />
        )
        break
      case JSONSchemaType.boolean:
        content = (
          <Checkbox
            name={name}
            defaultValue={Boolean(selfSchema.defaultValue) as any}
            label={selfSchema.title}
            helperText={selfSchema.description}
            disabled={disabled}
            color='primary'
          />
        )
        break
      case JSONSchemaType.object:
        renderAsIndividual = true

        content = (
          <>
            {selfSchema.title && (
              <>
                <Typography variant='h6'>{selfSchema.title}</Typography>
                <Divider className={classes.objectTitleDivider} />
              </>
            )}
            {selfSchema.description && (
              <FormHelperText className={classes.objectDescription}>
                {selfSchema.description}
              </FormHelperText>
            )}
            {Object.keys(properties).map((propertyName, i) => (
              <JSONSchemaField
                definitionGetter={definitionGetter}
                key={propertyName}
                className={i > 0 ? classes.mtop : undefined}
                name={`${name}.${propertyName}`}
                schema={schema}
                path={`${path ? path + '.' : ''}properties.${propertyName}`}
                disabled={disabled}
              />
            ))}
          </>
        )

        break
    }
  }

  return (
    <Paper
      elevation={renderAsIndividual ? 1 : 0}
      className={classnames(
        renderAsIndividual ? classes.individualWrapper : classes.wrapper,
        className
      )}
    >
      {content}
    </Paper>
  )
}

export default JSONSchemaField

export const getDefaultValuesBySchema = (schema: JSONSchema): any => {
  if (schema.defaultValue !== undefined) return schema.defaultValue

  const defaultValue: { [key: string]: any } = {}
  switch (schema.type) {
    case JSONSchemaType.array:
      return []
    case JSONSchemaType.object:
      for (const [propName, propSchema] of Object.entries(
        schema.properties || {}
      )) {
        defaultValue[propName] = getDefaultValuesBySchema(
            propSchema as JSONSchema
        )
      }
      return defaultValue
    default:
      return null
  }
}
