import React from 'react'
import {
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Theme
} from '@material-ui/core'
import { Error } from '@material-ui/icons'
import classNames from 'classnames'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2)
    }
  })
)

interface SchemaUserErrorsListProps {
  className?: string
  errors: {
    [key: string]: string
  }
}

const SchemaUserErrorsList: React.FC<SchemaUserErrorsListProps> = ({
  className,
  errors
}) => {
  const classes = useStyles()

  if (!errors || Object.keys(errors).length === 0) return null

  return (
    <Paper className={classNames(classes.paper, className)}>
      <List>
        {Object.entries(errors).map(([exportMethod, reason]) => (
          <ListItem key={exportMethod}>
            <ListItemIcon>
              <Error color='secondary' />
            </ListItemIcon>
            <ListItemText primary={reason} secondary={exportMethod} />
          </ListItem>
        ))}
      </List>
    </Paper>
  )
}

export default SchemaUserErrorsList
