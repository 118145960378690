import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Divider, makeStyles, Paper, TextField, Theme, Typography } from '@material-ui/core'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useApiErrorsParser } from 'hooks/api'
import { selectAuth } from 'features/auth/authSlice'
import GoogleIcon from '../../components/icons/GoogleIcon'
import { Input } from '../../components/fields'
import { useUpdateUserMutation } from '../../app/services/api'
import { useSnackbar } from 'notistack'


const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(4, 4)
  },
  titleDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  sectionTitle: {
    margin: theme.spacing(2, 0, 1)
  },
  apiServicesContainer: {
    '& > *': {
      marginTop: theme.spacing(2)
    }
  },
  vtigerInputs: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > *:not(:first-child)': {
      marginTop: theme.spacing(2)
    }
  },
  formButtons: {
    marginTop: theme.spacing(4),
    '& > *:not(:last-child)': {
      marginRight: theme.spacing(2)
    }
  }
}))


type UserFormValues = {
  vtiger_username: string
  vtiger_access_key: string
  vtiger_base_url: string
}

const userFormYupSchema = yup.object().shape({
  vtiger_username: yup.string(),
  vtiger_access_key: yup.string(),
  vtiger_base_url: yup.string()
})


const ProfilePage: React.FC = () => {
  const { token, user } = useSelector(selectAuth)
  const [updateUser, { isLoading }] = useUpdateUserMutation()
  const formMethods = useForm<UserFormValues>({
    defaultValues: {
      vtiger_username: (user && user.vtiger_username) || '',
      vtiger_access_key: (user && user.vtiger_access_key) || '',
      vtiger_base_url: (user && user.vtiger_base_url) || ''
    },
    resolver: yupResolver(userFormYupSchema)
  })
  const parseError = useApiErrorsParser(formMethods.setError)
  const { enqueueSnackbar } = useSnackbar()
  const styles = useStyles()

  const handleGoogleClick = () => {
    if (token) {
      if (!user?.has_access_to_google) {
        window.location.replace(
          `${process.env.REACT_APP_GOOGLE_AUTHORIZE_URL}?token=${token}`
        )
      } else if (
        window.confirm('Are you sure you want to revoke Google credentials?')
      ) {
        window.location.replace(
          `${process.env.REACT_APP_GOOGLE_REVOKE_URL}?token=${token}`
        )
      }
    }
  }

  const onSubmit = formMethods.handleSubmit((data) => {
    updateUser(data)
      .unwrap()
      .catch((e) => {
        parseError(e)
      })
      .then((data) => {
        enqueueSnackbar('Changes saved', {variant: 'success'})
        formMethods.reset({
          vtiger_username: data?.vtiger_username || '',
          vtiger_access_key: data?.vtiger_access_key || ''
        })
      })
  })

  return (
    <Paper className={styles.paper}>
      <Typography variant='h4'>Profile page</Typography>
      <Divider className={styles.titleDivider} />
      <FormProvider {...formMethods}>
        <form onSubmit={onSubmit}>
          <TextField
            variant='outlined'
            id='email'
            label='Email Address'
            disabled={true}
            // defaultValue=' ' // Fix for bug with label overlaying
            value={user?.email || ' '}
            helperText='This field is read-only'
          />
          <Typography variant="h5" className={styles.sectionTitle}>
            API Services
          </Typography>
          <Divider/>
          <div className={styles.apiServicesContainer}>

            <div className={styles.vtigerInputs}>
              <Input
                name="vtiger_username"
                disabled={isLoading}
                label="Vtiger Username"
                variant="outlined"
              />
              <Input
                name="vtiger_access_key"
                disabled={isLoading}
                label="Vtiger Access Key"
                variant="outlined"
              />
              <Input
                name="vtiger_base_url"
                disabled={isLoading}
                label="Vtiger Base URL"
                variant="outlined"
              />
            </div>

            <Divider/>

            <Button
              variant={user?.has_access_to_google ? 'contained' : 'outlined'}
              color={user?.has_access_to_google ? 'primary' : 'default'}
              startIcon={<GoogleIcon />}
              onClick={handleGoogleClick}
            >
              {user?.has_access_to_google
                ? 'Revoke Google credentials'
                : 'Authorize in Google'}
            </Button>

            <div className={styles.formButtons}>
              <Button type="submit" variant="contained" color="primary">Update</Button>
              <Button onClick={() => formMethods.reset()} variant="outlined">
                Reset
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </Paper>
  )
}

export default ProfilePage
