import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { SnackbarProvider } from 'notistack'
import Grow from '@material-ui/core/Grow'

import App from './App'
import './index.css'
import store from './app/store'


ReactDOM.render(
  <Provider store={store}>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      TransitionComponent={Grow as any}
      disableWindowBlurListener={true}
      autoHideDuration={3500}
    >
      <App />
    </SnackbarProvider>
  </Provider>,
  document.getElementById('root')
)
