import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query/react'
import {
  Button,
  CircularProgress,
  createStyles,
  Divider,
  makeStyles,
  Paper,
  Theme,
  Typography
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import deepcopy from 'deepcopy'

import {
  Checkbox,
  ExportMethodsField,
  Input,
  MetricsField,
  ReportObjectsField,
  Select
} from 'components/fields'
import { useAddModelMutation, useGetModelsQuery, useUpdateModelMutation } from 'app/services/api'
import { useApiErrorsParser } from 'hooks/api'
import { ReportModel, StatisticsPeriod } from 'types'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2)
    },
    updateId: {
      color: theme.palette.text.disabled,
      marginLeft: theme.spacing(1)
    },
    titleDivider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        marginTop: theme.spacing(2)
      }
    },
    actionButton: {
      marginRight: theme.spacing(2)
    }
  })
)

const yupSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  is_active: yup.boolean(),
  statistics_period: yup.string().required(),
  include_trend: yup.boolean(),
  report_objects: yup.array(yup.number()),
  metrics: yup.array(
    yup.object({
      name: yup.string().required()
    })
  ),
  export_methods: yup.array(
    yup.object({
      name: yup.string().required()
    })
  )
})

interface IFormInputs {
  name: string
  is_active: boolean
  statistics_period: StatisticsPeriod
  include_trend: boolean
  report_objects: number[]
  metrics: Record<string, any>[]
  export_methods: Record<string, any>[]
}

const defaultValues: Partial<ReportModel> = {
  name: '',
  is_active: true,
  statistics_period: StatisticsPeriod.Month,
  include_trend: true,
  report_objects: [],
  metrics: [],
  export_methods: []
}

const CreateUpdateModel: React.FC = () => {
  const classes = useStyles()
  const { id }: { id?: string } = useParams()
  const history = useHistory()
  const isCreate = !id
  const {
    reportModel,
    error: reportModelError,
    isFetching
  } = useGetModelsQuery(isCreate ? skipToken : undefined, {
    selectFromResult: ({ data, ...other }) => ({
      reportModel: data?.find((model) => model.id === (id ? Number(id) : null)),
      ...other
    })
  })
  const [updateModel, { isLoading: isUpdating }] = useUpdateModelMutation()
  const [createModel, { isLoading: isCreating }] = useAddModelMutation()
  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(yupSchema)
  })
  const { enqueueSnackbar } = useSnackbar()
  const parseErrors = useApiErrorsParser(formMethods.setError)

  const [isDisabled, setIsDisabled] = React.useState(false)

  React.useEffect(() => {
    if (!isCreate && !isFetching && reportModel) {
      const { id, ...defaults } = reportModel
      formMethods.reset(defaults)
    }
  }, [isCreate, isFetching, reportModel])

  React.useEffect(() => {
    if (
      formMethods.formState.isSubmitting ||
      isUpdating ||
      isCreating ||
      isFetching
    ) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [formMethods.formState.isSubmitting, isUpdating, isCreating, isFetching])

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    const payload: any = deepcopy(data)

    if (isCreate) {
      createModel(payload)
        .unwrap()
        .then(() => {
          enqueueSnackbar('Report model has been created', {
            variant: 'success'
          })
          history.replace('/models')
        })
        .catch((e) => {
          enqueueSnackbar('Failed to create model', { variant: 'error' })
          parseErrors(e)
        })
    } else {
      payload.id = reportModel?.id
      updateModel(payload)
        .unwrap()
        .then(() => {
          enqueueSnackbar('Changes saved', { variant: 'success' })
          history.replace('/models')
        })
        .catch((e) => {
          enqueueSnackbar('Failed to update model', { variant: 'error' })
          parseErrors(e)
        })
    }
  }

  if (!isCreate && isFetching) {
    return <CircularProgress size='5rem' />
  }

  if (!isCreate && !isFetching && reportModel === undefined) {
    return <Typography variant='h4'>{'Model not found :('}</Typography>
  }

  if (!isCreate && reportModelError) {
    return <Typography variant='h4'>{'Failed to load model'}</Typography>
  }

  let pageTitle
  if (isCreate) {
    pageTitle = <Typography variant='h4'>Create new model</Typography>
  } else {
    pageTitle = (
      <Typography variant='h4'>
        <span>Update model</span>
        <span className={classes.updateId}>#{reportModel?.id ?? '?'}</span>
      </Typography>
    )
  }

  return (
    <Paper className={classes.paper}>
      {pageTitle}
      <Divider className={classes.titleDivider} />
      <FormProvider {...formMethods}>
        <form
          className={classes.form}
          onSubmit={formMethods.handleSubmit(onSubmit)}
          noValidate
        >
          <Input
            disabled={isDisabled}
            name='name'
            variant='outlined'
            label='Name'
            autoFocus
          />

          <Checkbox disabled={isDisabled} name='is_active' label='Is active' />

          <Select
            name='statistics_period'
            label='Statistics period'
            options={[
              { value: 'WEEK', label: 'Week' },
              { value: 'MONTH', label: 'Month' },
              { value: 'QUARTER', label: 'Quarter' }
            ]}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
          />

          <Checkbox
            disabled={isDisabled}
            name='include_trend'
            label='Include metrics trend'
          />

          <ReportObjectsField
            control={formMethods.control}
            name='report_objects'
          />

          <MetricsField name='metrics' control={formMethods.control} />

          <ExportMethodsField disabled={isDisabled} name='export_methods' />

          <div>
            <Button
              className={classes.actionButton}
              type='submit'
              color='primary'
              variant='contained'
              disabled={isDisabled}
            >
              {isCreate ? 'Create' : 'Update'}
            </Button>
            <Button
              variant='outlined'
              onClick={() => history.replace('/models')}
              disabled={isDisabled}
            >
              Cancel
            </Button>
          </div>
        </form>
      </FormProvider>
    </Paper>
  )
}

export default CreateUpdateModel
