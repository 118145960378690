import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { selectAuth } from '../features/auth/authSlice'


const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { isAuthenticated } = useSelector(selectAuth)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : isAuthenticated === null ? (
          <CircularProgress size='5rem' />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location, referrer: location }
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
