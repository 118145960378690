import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { skipToken } from '@reduxjs/toolkit/query/react'
import {
  Button,
  CircularProgress,
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Theme,
  Typography
} from '@material-ui/core'
import { Error } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { Input, JSONSchemaField } from 'components/fields'
import {
  useAddObjectMutation,
  useGetObjectBindingsSchemaQuery,
  useGetObjectQuery,
  useUpdateObjectMutation
} from 'app/services/api'
import { useApiErrorsParser } from 'hooks/api'
import { JSONSchemaType, ReportObject } from 'types'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2)
    },
    updateId: {
      color: theme.palette.text.disabled,
      marginLeft: theme.spacing(1)
    },
    titleDivider: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2)
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      '& > *': {
        marginTop: theme.spacing(2)
      }
    },
    bindingSchemaErrorsPaper: {
      padding: theme.spacing(2)
    },
    actionButton: {
      marginRight: theme.spacing(2)
    }
  })
)

const yupSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  bindings: yup.object()
})

interface IFormInputs {
  name: string
  bindings: { [key: string]: any }
}

const defaultValues: Partial<ReportObject> = {
  name: '',
  bindings: {}
}

const CreateUpdateObject: React.FC = () => {
  const classes = useStyles()
  const { id }: { id?: string } = useParams()
  const history = useHistory()
  const isCreate = !id
  const {
    data: reportObject,
    error: reportObjectError,
    isFetching
  } = useGetObjectQuery(isCreate ? skipToken : Number(id))
  const [updateObject, { isLoading: isUpdating }] = useUpdateObjectMutation()
  const [createObject, { isLoading: isCreating }] = useAddObjectMutation()
  const {
    data: bindingsSchema,
    error: bindingsSchemaError,
    isFetching: isBindingsSchemaFetching
  } = useGetObjectBindingsSchemaQuery()
  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(yupSchema)
  })
  const { enqueueSnackbar } = useSnackbar()
  const parseErrors = useApiErrorsParser(formMethods.setError)

  const [formWillReset, setFormWillReset] = React.useState(true)

  const [isDisabled, setIsDisabled] = React.useState(false)

  React.useEffect(() => {
    if (!isCreate && !isFetching && reportObject) {
      const { id, ...defaults } = reportObject
      formMethods.reset(defaults)
      setFormWillReset(true)
    }
  }, [isCreate, isFetching, reportObject])

  React.useEffect(() => {
    if (formWillReset) setFormWillReset(false)
  }, [formWillReset])

  React.useEffect(() => {
    if (
      formMethods.formState.isSubmitting ||
      isUpdating ||
      isCreating ||
      isFetching
    ) {
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
  }, [formMethods.formState.isSubmitting, isUpdating, isCreating, isFetching])

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    if (isCreate) {
      createObject(data)
        .unwrap()
        .then(() => {
          enqueueSnackbar('Report object has been created', {
            variant: 'success'
          })
          history.replace('/objects')
        })
        .catch((e) => {
          enqueueSnackbar('Failed to create object', { variant: 'error' })
          parseErrors(e)
        })
    } else {
      updateObject({ ...data, id: reportObject!.id })
        .unwrap()
        .then(() => {
          enqueueSnackbar('Changes saved', { variant: 'success' })
          history.replace('/objects')
        })
        .catch((e) => {
          enqueueSnackbar('Failed to update object', { variant: 'error' })
          parseErrors(e)
        })
    }
  }

  if (!isCreate && isFetching) {
    return <CircularProgress size='5rem' />
  }

  if (!isCreate && !isFetching && reportObject === undefined) {
    return <Typography variant='h4'>{'Object not found :('}</Typography>
  }

  if (!isCreate && reportObjectError) {
    return <Typography variant='h4'>{'Failed to load object'}</Typography>
  }

  let pageTitle
  if (isCreate) {
    pageTitle = <Typography variant='h4'>Create new object</Typography>
  } else {
    pageTitle = (
      <Typography variant='h4'>
        <span>Update object</span>
        <span className={classes.updateId}>#{reportObject?.id ?? '?'}</span>
      </Typography>
    )
  }

  return (
    <Paper className={classes.paper}>
      {pageTitle}
      <Divider className={classes.titleDivider} />
      <FormProvider {...formMethods}>
        <form
          className={classes.form}
          onSubmit={formMethods.handleSubmit(onSubmit)}
          noValidate
        >
          <Input
            disabled={isDisabled}
            name='name'
            variant='outlined'
            label='Name'
            autoFocus
          />

          {isBindingsSchemaFetching || formWillReset ? (
            <CircularProgress />
          ) : bindingsSchemaError ? (
            <Typography>Failed to load bindings schema.</Typography>
          ) : (
            <>
              {bindingsSchema?.schema &&
                Object.keys(bindingsSchema.schema).length > 0 && (
                <JSONSchemaField
                  name='bindings'
                  schema={{
                    type: JSONSchemaType.object,
                    title: 'Bindings',
                    properties: bindingsSchema.schema
                  }}
                  disabled={isDisabled}
                />
              )}
              {bindingsSchema?.errors &&
                Object.keys(bindingsSchema.errors).length > 0 && (
                <Paper className={classes.bindingSchemaErrorsPaper}>
                  <Typography variant='h6'>
                      Failed to load bindings:
                  </Typography>
                  <List>
                    {Object.entries(bindingsSchema.errors).map(
                      ([bindingName, reason]) => (
                        <ListItem key={bindingName}>
                          <ListItemIcon>
                            <Error color='secondary' />
                          </ListItemIcon>
                          <ListItemText
                            primary={reason}
                            secondary={bindingName}
                          />
                        </ListItem>
                      )
                    )}
                  </List>
                </Paper>
              )}
            </>
          )}

          <div>
            <Button
              className={classes.actionButton}
              type='submit'
              color='primary'
              variant='contained'
              disabled={isDisabled}
            >
              {isCreate ? 'Create' : 'Update'}
            </Button>
            <Button
              variant='outlined'
              onClick={() => history.replace('/objects')}
              disabled={isDisabled}
            >
              Cancel
            </Button>
          </div>
        </form>
      </FormProvider>
    </Paper>
  )
}

export default CreateUpdateObject
