import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  makeStyles,
  Paper,
  Theme,
  Typography
} from '@material-ui/core'
import { Add, Delete, FileCopy, PlayCircleFilled, Refresh } from '@material-ui/icons'
import { DataGrid, GridColDef, GridRowId, GridToolbarContainer } from '@material-ui/data-grid'
import { Alert, AlertTitle } from '@material-ui/lab'
import parse from 'html-react-parser'

import {
  useDeleteModelsMutation,
  useDuplicateModelMutation,
  useGetModelsQuery,
  useMakeReportsMutation
} from 'app/services/api'
import { useApiErrorsParser } from 'hooks/api'
import { LastLaunchReport, ReportModelStatus, StatisticsPeriod } from 'types'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    titleDivider: {
      margin: theme.spacing(1, 0, 2)
    },
    messagesContainer: {
      width: '100%',
      '& > *': {
        overflowWrap: 'anywhere'
      },
      '& > * + *': {
        marginTop: theme.spacing(2)
      }
    }
  })
)

interface LaunchReportDialogProps {
  launchReport?: LastLaunchReport
}

const LaunchReportDialog: React.FC<LaunchReportDialogProps> = ({ launchReport }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  if (!launchReport) {
    return <Typography>-</Typography>
  }

  const messages = launchReport?.messages ? launchReport.messages.slice() : []

  if (launchReport?.unhandled_error)
    messages.push({
      title: 'Unhandled error',
      text: launchReport.unhandled_error,
      type: 'error'
    })

  return (
    <div>
      <Button
        variant='contained'
        color='primary'
        onClick={(e) => {
          e.stopPropagation()
          e.preventDefault()
          handleOpen()
        }}
      >
        Open
      </Button>
      <Dialog scroll='paper' open={open} onClose={handleClose} fullWidth>
        <DialogContent>
          {messages.length === 0 ?
            <Typography variant='h5'>There are no messages and errors</Typography> :
            <div className={classes.messagesContainer}>
              {messages.map((message, index) => (
                <Alert severity={message.type || 'info'} key={index}>
                  <AlertTitle>{message.title}</AlertTitle>
                  {parse(message.text)}
                </Alert>
              ))}
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 200
  },
  {
    field: 'is_active',
    headerName: 'Active',
    width: 100,
    type: 'boolean'
  },
  {
    field: 'statistics_period',
    headerName: 'Period',
    width: 100,
    valueFormatter: (params) => {
      const value = params.value
      return StatisticsPeriod[value as any] ?? value
    }
  },
  {
    field: 'include_trend',
    headerName: 'With trend',
    width: 140,
    type: 'boolean'
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 200,
    valueFormatter: (params) => {
      const value = params.value
      return ReportModelStatus[value as any] ?? value
    }
  },
  {
    field: 'last_launch_time',
    headerName: 'Last launch time',
    flex: 1,
    minWidth: 200,
    valueFormatter: (params) => {
      if (params.value) {
        const date = new Date(params.value as string)
        return date.toLocaleString()
      }
      return '-'
    }
  },
  {
    field: 'last_launch_report',
    headerName: 'Last launch report',
    flex: 1,
    minWidth: 200,
    renderCell: (params) => <LaunchReportDialog launchReport={params.value as any} />
  }
]

const ModelsGridToolbar: React.FC<{ selectedModelIds: number[] }> = ({
  selectedModelIds
}) => {
  const [deleteModels, { isLoading: isDeleting }] = useDeleteModelsMutation()
  const [makeReports, { isLoading: isRunning }] = useMakeReportsMutation()
  const [duplicateModel, { isLoading: isDuplicating }] =
    useDuplicateModelMutation()
  const { refetch, isFetching } = useGetModelsQuery()
  const history = useHistory()
  const parseErrors = useApiErrorsParser()

  const isMutating = isDeleting || isRunning || isFetching || isDuplicating

  return (
    <GridToolbarContainer>
      <Button
        color='primary'
        startIcon={<Add />}
        onClick={() => history.push('/models/create')}
      >
        Create new
      </Button>
      <Button
        color='primary'
        startIcon={<Refresh />}
        disabled={isFetching}
        onClick={() => refetch()}
      >
        Refresh
      </Button>
      <Button
        disabled={selectedModelIds.length === 0 || isMutating}
        color='secondary'
        startIcon={<Delete />}
        onClick={() =>
          window.confirm(
            `Are you sure you want to delete ${
              selectedModelIds.length
            } report model${selectedModelIds.length === 1 ? '' : 's'}?`
          ) && deleteModels(selectedModelIds).unwrap().finally()
        }
      >
        Delete
      </Button>
      <Button
        disabled={selectedModelIds.length !== 1 || isMutating}
        color='primary'
        startIcon={<FileCopy />}
        onClick={() => {
          if (selectedModelIds.length === 1) {
            duplicateModel(selectedModelIds[0])
              .unwrap()
              .catch((e) => parseErrors(e))
          }
        }}
      >
        Duplicate
      </Button>
      <Button
        disabled={selectedModelIds.length === 0 || isMutating}
        color='primary'
        startIcon={<PlayCircleFilled />}
        onClick={() => {
          makeReports(selectedModelIds)
            .unwrap()
            .catch((e) => parseErrors(e))
        }}
      >
        Make reports
      </Button>
    </GridToolbarContainer>
  )
}
const ModelsList: React.FC = () => {
  const { data: models, isFetching, isError } = useGetModelsQuery()
  const classes = useStyles()
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([])
  const history = useHistory()

  React.useEffect(() => {
    if (models) {
      const modelIds = models.map((model) => model.id)
      setSelectionModel(
        selectionModel.filter((modelId) => modelIds.includes(modelId as number))
      )
    }
  }, [models])

  return (
    <Paper className={classes.root}>
      <Typography variant='h4'>Report Models</Typography>
      <Divider className={classes.titleDivider} />
      <DataGrid
        error={isError ? 'Error occurred' : undefined}
        rows={models || []}
        columns={columns}
        loading={isFetching}
        disableColumnMenu
        autoHeight
        checkboxSelection
        disableSelectionOnClick
        isRowSelectable={(params) =>
          params.row?.status === ReportModelStatus.Idle
        }
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel)
        }}
        selectionModel={selectionModel}
        onRowClick={({ row: { id } }) =>
          !isFetching && history.push(`/models/${id}`)
        }
        components={{ Toolbar: ModelsGridToolbar }}
        componentsProps={{
          toolbar: {
            selectedModelIds: selectionModel
          }
        }}
      />
    </Paper>
  )
}

export default ModelsList
