import React from 'react'
import { useController } from 'react-hook-form'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  createStyles,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'

import { useGetObjectsQuery } from 'app/services/api'
import { ReportObject } from 'types'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionSecondaryHeading: {
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2)
    },
    accordionDetails: {
      display: 'flex',
      flexDirection: 'column'
    },
    linearProgress: {
      marginBottom: theme.spacing(2)
    },
    list: {
      height: '300px',
      width: '230px',
      overflow: 'auto'
    },
    transferListButton: {
      margin: theme.spacing(0.5, 0)
    }
  })
)

/** From A which are not in B */
function not(a: number[], b: number[]) {
  return a.filter((value) => b.indexOf(value) === -1)
}

/** From A which are also in B */
function intersection(a: number[], b: number[]) {
  return a.filter((value) => b.indexOf(value) !== -1)
}

function getReportObjectIdsAndNames(
  reportObjects: ReportObject[] = []
): [number[], { [key: number]: string }] {
  const ids: number[] = []
  const names: { [key: number]: string } = {}
  for (const obj of reportObjects) {
    ids.push(obj.id)
    names[obj.id] = obj.name
  }
  return [ids, names]
}

interface ReportObjectsFieldProps {
  name: string
  control: any
  disableWhileSubmitting?: boolean
}

const ReportObjectsField: React.FC<ReportObjectsFieldProps> = ({
  name,
  control,
  disableWhileSubmitting = true
}) => {
  const classes = useStyles()
  const {
    data: reportObjects,
    error,
    isLoading,
    isFetching
  } = useGetObjectsQuery()
  const [reportObjectIds, reportObjectNames] = React.useMemo(
    () => getReportObjectIdsAndNames(reportObjects),
    [reportObjects]
  )
  const {
    field: { value: fieldValue, onChange },
    formState: { isSubmitting }
  } = useController({ name, control, defaultValue: [] })
  const isDisabled = isFetching || (disableWhileSubmitting && isSubmitting)
  const [checked, setChecked] = React.useState<number[]>([])

  const right = fieldValue ?? []
  const left = not(reportObjectIds, right)
  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleAllRight = () => {
    onChange(right.concat(left))
  }

  const handleCheckedRight = () => {
    onChange(right.concat(leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    onChange(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const handleAllLeft = () => {
    onChange([])
  }

  if (isLoading) {
    return <CircularProgress />
  } else if (error) {
    return <Typography>{'Failed to load report objects.'}</Typography>
  }

  const objectsList = (title: React.ReactNode, items: number[]) => (
    <Card>
      <CardHeader
        title={title}
        subheader={`${items.length} item${items.length === 1 ? '' : 's'}`}
      />
      <Divider />
      <List className={classes.list} dense component='div' role='list'>
        {items.map((value: number) => {
          const labelId = `objects-transfer-list-all-item-${value}-label`

          return (
            <ListItem
              key={value}
              role='listitem'
              button
              disabled={isDisabled}
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  color='primary'
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={reportObjectNames[value] || `Report object #${value}`}
              />
            </ListItem>
          )
        })}
      </List>
    </Card>
  )

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>Report objects</Typography>
        <Typography
          className={classes.accordionSecondaryHeading}
        >{`(${right.length} specified)`}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {isFetching && <LinearProgress className={classes.linearProgress} />}
        <Grid container spacing={2} justifyContent='center' alignItems='center'>
          <Grid item>{objectsList('Available', left)}</Grid>
          <Grid item>
            <Grid container direction='column' alignItems='center'>
              <Button
                variant='outlined'
                size='small'
                className={classes.transferListButton}
                onClick={handleAllRight}
                disabled={left.length === 0 || isDisabled}
                aria-label='move all right'
              >
                ≫
              </Button>
              <Button
                variant='outlined'
                size='small'
                className={classes.transferListButton}
                onClick={handleCheckedRight}
                disabled={leftChecked.length === 0 || isDisabled}
                aria-label='move selected right'
              >
                &gt;
              </Button>
              <Button
                variant='outlined'
                size='small'
                className={classes.transferListButton}
                onClick={handleCheckedLeft}
                disabled={rightChecked.length === 0 || isDisabled}
                aria-label='move selected left'
              >
                &lt;
              </Button>
              <Button
                variant='outlined'
                size='small'
                className={classes.transferListButton}
                onClick={handleAllLeft}
                disabled={right.length === 0 || isDisabled}
                aria-label='move all left'
              >
                ≪
              </Button>
            </Grid>
          </Grid>
          <Grid item>{objectsList('Chosen', right)}</Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default ReportObjectsField
