import React from 'react'
import { createStyles, makeStyles, TextField, TextFieldProps } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import classnames from 'classnames'


const useStyles = makeStyles(() =>
  createStyles({
    textFieldInput: {}
  })
)

type InputProps = TextFieldProps & {
  name: string
  defaultValue?: any
  required?: boolean
  helperText?: string
  className?: string
  disableWhileSubmitting?: boolean
}

const Input: React.FC<InputProps> = ({
  name,
  defaultValue,
  required,
  helperText,
  className,
  disableWhileSubmitting = true,
  ...other
}) => {
  const classes = useStyles()
  const { control, formState } = useFormContext()
  const error: string | undefined = _.get(formState.errors, name)?.message

  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field }) => (
        <TextField
          className={classnames(classes.textFieldInput, className)}
          error={Boolean(error)}
          helperText={error || helperText || undefined}
          id={field.name}
          disabled={disableWhileSubmitting && formState.isSubmitting}
          {...other}
          {...field}
        />
      )}
    />
  )
}

export default Input
