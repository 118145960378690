import React from 'react'
import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  createStyles,
  FormControlLabel,
  FormHelperText,
  makeStyles
} from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import _ from 'lodash'
import classnames from 'classnames'


const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {}
  })
)

type CheckboxProps = MuiCheckboxProps & {
  name: string
  label?: string
  defaultValue?: boolean
  helperText?: string
  className?: string
  disableWhileSubmitting?: boolean
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  defaultValue,
  label,
  helperText,
  className,
  disableWhileSubmitting = true,
  ...other
}) => {
  const classes = useStyles()
  const { control, formState } = useFormContext()
  const error: string | undefined = _.get(formState.errors, name)?.message

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field }) => (
        <div className={classnames(classes.wrapper, className)}>
          <FormControlLabel
            control={
              <MuiCheckbox
                id={field.name}
                color='primary'
                disabled={disableWhileSubmitting && formState.isSubmitting}
                checked={Boolean(field.value)}
                {...other}
                {...field}
              />
            }
            label={label ?? ''}
          />
          <FormHelperText error={Boolean(error)}>
            {error || helperText || null}
          </FormHelperText>
        </div>
      )}
    />
  )
}

export default Checkbox
